<script>
    export default {
        props: {
            variant: {
                type: String,
                default: 'secondary'
            },

            text: {
                type: String
            },

            method: {
                type: Function,
                required: true
            },

            callback: {
                type: Function
            }
        },

        computed: {
            btnText: function () {
                return this.text || this.$t('base.save_and_close').ucFirst()
            },

            type: function () {
                return 'btn-' + this.variant
            },
        },

        methods: {
            click: function () {
                this.method().then(() => {
                    if (this.callback) {
                        this.callback()
                    }
                })
            },
        }
    }
</script>

<template>
    <button class="btn" :class="type" @click.prevent="click()">{{ $t('base.save_and_close').ucFirst() }}</button>
</template>

<style scoped>

</style>
