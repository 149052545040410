<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.zip_code === false}"
                        class="form-label"
                        for="zip_code"
                    >{{ $t('addresses::zip_codes.columns.zip_code').ucFirst() }} *</label
                    >

                    <input
                        id="zip_code"
                        v-model="model.zip_code"
                        :class="{'border-danger': states.zip_code === false}"
                        :placeholder="$t('addresses::zip_codes.placeholders.zip_code').ucFirst()"
                        class="form-control"
                        name="zip_code"
                        type="text"
                    />
                    <div :class="{'d-block': states.zip_code === false}" class="invalid-feedback">{{
                            errors.zip_code
                        }}
                    </div>
                </div>

                <div class="col-lg-8">
                    <label
                        :class="{'text-danger': states.city === false}"
                        class="form-label"
                        for="city"
                    >{{ $t('addresses::zip_codes.columns.city').ucFirst() }} *</label
                    >
                    <input
                        id="city"
                        v-model="model.city"
                        :class="{'border-danger': states.city === false}"
                        :placeholder="$t('addresses::zip_codes.placeholders.city').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.city === false}" class="invalid-feedback">{{ errors.city }}</div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ZipCodeForm",

    emits: ['saved'],

    props: {
        zipCode: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'zipCode/loading',
            'states': 'zipCode/states',
            'errors': 'zipCode/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.zipCode)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`zipCode/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/zip-codes/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        zipCode: function () {
            this.model = JSON.parse(JSON.stringify(this.zipCode))
        },
    },

    created() {
        // console.log(this.zipCode)
    }
}
</script>

<style scoped>

</style>
